<template>
    <main id="content" class="content bg-mirage">
        <div class="titlebar titlebar-sm scheme-dark bg-gray-2 bb-fade-black-005">

            <div class="titlebar-inner py-5">
                <div class="container titlebar-container">
                    <div class="row titlebar-container">

                        <div class="titlebar-col col-md-6">
                            <h1 class="font-size-46 mb-1 font-weight-bold">Contact Us</h1>
                            <p class="font-size-14 text-fade-dark-06">We enjoy building cool stuff.</p>
                        </div><!-- /.titlebar-col -->

                        <div class="titlebar-col col-md-6 text-md-right">
                            <ol class="breadcrumb reset-ul inline-nav">
                                <li><a href="/"><span>Home</span></a></li>
                                <li><span>Contact Us</span></li>
                            </ol>
                        </div><!-- /.titlebar-col -->

                    </div><!-- /.titlebar-row -->
                </div><!-- /.titlebar-container -->
            </div><!-- /.titlebar-inner -->

        </div>

        <section class="vc_row">
            <div class="container-fluid">
                <div class="row d-flex flex-wrap align-items-stretch">

                    <div class="lqd-column col-lg-4 col-lg-offset-1 col-xs-12 pt-90 pb-100">

                        <header class="fancy-title mb-40">
                            <h2 class="font-size-42 mb-1 white font-weight-bold">Now, about your project ....</h2>
                        </header>

                        <div class="contact-form contact-form-inputs-filled contact-form-button-block mb-60">
                            <form action="../assets/php/mailer.php" method="post" novalidate="novalidate">
                                <div class="row">
                                    <div class="col-md-12 mb-20">
                                        <label for="">What is your full name?</label>
                                        <input class="bg-fade-white-01" type="text" name="name" aria-required="true" aria-invalid="false" placeholder="e.g christopher davis" required>
                                    </div><!-- /.col-md-12 -->
                                    <div class="col-md-12 mb-20">
                                        <label for="">What is your email address?</label>
                                        <input class="bg-fade-white-01" type="email" name="email" aria-required="true" aria-invalid="false" placeholder="e.g davis@gmail.com" required>
                                    </div><!-- /.col-md-12 -->
                                    <div class="col-md-12 mb-20">
                                        <label for="">What is phone number?</label>
                                        <input class="bg-fade-white-01" type="tel" name="phone" aria-required="true" aria-invalid="false" placeholder="e.g +(234) 9057375755" required>
                                    </div><!-- /.col-md-12 -->
                                    <div class="col-md-12  mb-20">
                                        <label for="">Tell us about this project, the purpose of the project and problems we are solving. <br> what is this project about?</label>
                                        <textarea class="bg-fade-white-01" cols="10" rows="4" name="message" aria-required="true" aria-invalid="false" placeholder="Enter your message" required></textarea>
                                    </div><!-- /.col-md-12 -->
                                    <div class="col-md-12 text-md-right">
                                        <input class="font-weight-bold font-size-14 ltr-sp-1" type="submit" value="SEND YOUR MESSAGE">
                                    </div><!-- /.col-md-12 -->
                                </div><!-- /.row -->
                            </form>
                            <div class="contact-form-result hidden"></div><!-- /.contact-form-result -->
                        </div><!-- /.contact-form -->

                        <div class="contact-others">
                            <div class="contact-others__icon">
                                <img src="/assets/img/mail.svg" alt="">
                            </div>
                            <div class="contact-others__details">
                                <h5 class="contact-others__header">
                                    Email Us
                                </h5>
                                <div class="contact-others__description">
                                    <p>Email us for general queries, including marketing and partnership opportunities.</p>
                                    <a href="mailto:info@visontechers.com">info@visontechers.com</a>
                                </div>
                            </div>
                        </div>

                        <div class="contact-others">
                            <div class="contact-others__icon">
                                <img src="/assets/img/phone.svg" alt="">
                            </div>
                            <div class="contact-others__details">
                                <h5 class="contact-others__header">
                                    Call us
                                </h5>
                                <div class="contact-others__description">
                                    <p>Call us to speak to a member of our team. We are always happy to help.</p>
                                    <a>+ (234) 8035491120</a>
                                </div>
                            </div>
                        </div>

                    </div><!-- /.lqd-column col-lg-4 col-md-6 -->

                    <div class="lqd-column col-lg-6 col-xs-12 col-lg-offset-1 d-flex">

                        <div class="ld-gmap-container stretch-to-right">
                            <div class="ld-gmap">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.8039906650242!2d3.3235037147708093!3d6.546416695265105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8e132e0757ab%3A0x1ffd6e4d09e1334!2s10%20Princess%20Aina%20Jegede%20Cl%2C%20Ajao%20Estate%2C%20Street!5e0!3m2!1sen!2sng!4v1621942782281!5m2!1sen!2sng" style="border:0;width: 100%;height: 100%" allowfullscreen="" loading="lazy"></iframe>
                            </div><!-- /.ld-gmap -->
                        </div><!-- /.ld-gmap-container -->

                    </div><!-- /.col-md-6 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>

    </main>
</template>
